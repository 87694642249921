#welcome {
  /* display: flex;
  flex-direction: column; */
  height: 100vh;
  width: 100%;
  text-align: center;
  /* opacity: 50%;
  background-color: rgba(0, 0, 0, 0.9);    */
}

.welcome-overlay {
  position: absolute; /* Fixed to screen */
  top: 0;
  left: 0;
  width: 150vw;
  height: 150vh;
  background-color: #171616;
  /* Semi-transparent */
  z-index: 9; /* Z-index set to 9 */
  opacity: 90%;
}

/* .opaque {
  background-color: rgba(0, 0, 0, 0.8); 
  z-index: 99;
}

.non-opaque {
  background-color: transparent; /* Transparent background */
/*}

.emotion-widget {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 100;
} */

/* #welcome-div-navbar {
    height: 3vh;
    background-color: white;
  } */

#welcome-div-top {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  /* height: 200px; */
  height: 25vh;
  width: 100%;
  text-align: center;
  color: #cc3366;
  background-image: none;
}

.logo-img-wrapper {
  height: max-content;
  width: auto;
}

#logo-img {
  width: 100%;
  height: auto;
}
@media (max-height: 600px) {
  #logo-img {
    width: 45%;
    height: auto;
  }
}

#welcome-div-bottom {
  display: flex;
  margin: 0px;
  padding: 0px;
  justify-content: center;
  align-items: center;
  height: 45vh;
  width: 100%;
  background-color: #cc3366;
  color: #f5f5f5;
  height: max-content;
}

#welcome-div-footer {
  height: 30vh;
  width: 100%;
  padding: 10px;
}

.icons img {
  margin-bottom: 30px;
  margin-right: 10px;
  margin-left: 5px;
  margin-top: 10px;
  text-decoration: none;
  width: 70px;
}

/* Navigation Buttons */

.nav-btn-div {
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: end;
  width: 100%;
  background: rgb(33, 33, 33, 0);
  height: 55px;
  overflow-y: hidden;
  overflow-x: hidden;
  z-index: 1000;
}
#complete-dance {
  background-color: #00000000;
  border: 1px solid#cc3366;
  padding: 5px;
  width: 100px;
  border-radius: 10px;
  text-align: center;
  cursor: pointer;
  font-weight: 500;
}
#complete-dance:hover {
  background-color: #cc3366;
  border: 1px solid#00000000;
}
#pinkBtn {
  background-color: #cc3366;
  border: 2px solid#cc3366;
  padding: 5px;
  width: 100px;
  border-radius: 10px;
  text-align: center;
  cursor: pointer;
  font-weight: 500;
}
#pinkBtn:hover {
  background-color: #cc3366;
  border: 2px solid#fff;
}

#outlinedPinkBtn {
  background-color: #00000000;
  border: 2px solid#cc3366;
  padding: 5px;
  width: 100px;
  border-radius: 10px;
  text-align: center;
  cursor: pointer;
  font-weight: 500;
  margin-right: 10px;
}
#outlinedPinkBtn:hover {
  background-color: #cc3366;
  border: 2px solid#cc3366;
}

/* .link {
  margin: 1px;
} */

.welcome-link img {
  height: 28px;
}

#homeLogin {
  position: fixed;
  top: 0px;
  right: 20px;
  /* display: flex;
    flex-direction: row; */
}

#homeBtn {
  height: 28px;
}

#profileBtn {
  height: 28px;
}

#homeLogin #submit {
  padding: 0;
  border: none;
  background: none;
}

#LogoutBtn {
  height: 28px;
}

/* FLIP CARDS MENU */

.buttonarea {
  /*display: inline-block;*/
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #cc3366;
  height: max-content;
  width: 100vw;
  padding: 0;
}

.buttonarea h2 {
  color: #cc3366;
  font-size: 26px;
  font-family: "Nunito";
  font-weight: 800;
  margin-top: 20px !important;
}

.buttonarea p {
  font-size: large;
  padding: 0px;
  margin: 15px 30px;
}

.attendclass {
  background-color: #fff;
  width: 260px;
  height: 260px;
  border-radius: 20px;
  display: inline-block;
  vertical-align: middle;
}

.gap {
  display: inline-block;
  width: 10px;
}

#form1 {
  color: #cc3366;
  margin: 0px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
#form1 input {
  height: 50px;
  width: 80%;
  font-size: 16px;
  font-weight: 500;
  width: auto;
}

.room {
  height: 25px;
  border-radius: 10px;
  border-style: solid;
  text-align: center;
  margin-bottom: 9px;
}

.btnGo {
  height: auto;
  width: 50px;
  margin: 0px;
  padding: 5px;
  border-radius: 10px;
  color: #fff;
  background-color: #cc3366;
  border-style: solid;
  border-color: #cc3366;
}

.row {
  margin: 0px !important;
}

/* FLIP */

.flip-container {
  perspective: 1000px;
  transform-style: preserve-3d;
  display: inline-block;
  vertical-align: middle;
  margin: 20px 10px;
}

.flip-container:hover .back {
  transform: rotateY(0deg);
}

.flip-container:hover .front {
  transform: rotateY(180deg);
}

.flip-container,
.front,
.back {
  width: 350px;
  height: 350px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.flip-container button {
  font-size: larger;
  font-weight: bolder;
  width: 130px;
}

.flipper {
  transition: 1s;
  transform-style: preserve-3d;
  position: relative;
}

.front,
.back {
  backface-visibility: hidden;
  transition: 1s;
  transform-style: preserve-3d;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #fff;
  border-radius: 20px;
}

.front {
  z-index: 0;
  transform: rotateY(0deg);
  position: relative;
  align-items: center;
}

.front img {
  padding-bottom: 20px;
  /* align-items: flex-end; */
}

#fimg3 {
  padding-top: 10px;
}

/* .flipper-image {
  padding-bottom: 0px;
  margin: 0px;
} */

/* .flipper-image2 {
  padding-bottom: 20px;
  margin: 0px;
} */

.back {
  transform: rotateY(-180deg);
  margin-top: 0px;
  box-shadow: 15px 10px 10px #6d1b37;
}

.back p {
  font-size: 18px;
  color: #000;
  text-align: center;
}

img.map,
map area {
  outline: none;
}

.container {
  font-family: "Roboto", sans-serif;
}

.flipcontainer {
  width: 200px;
  height: 180px;
  position: relative;
  border: 0px;
  margin: 20px;
}

.card {
  width: 100%;
  height: 100%;
  position: absolute;
  transition: transform 1s;
  transform-style: preserve-3d;
  -webkit-transform-origin: 50% 50%;
}

.card div {
  display: block;
  height: 100%;
  width: 100%;
  line-height: 260px;
  text-align: center;
  font-weight: bold;
  font-size: 140px;
  position: absolute;
  backface-visibility: hidden;
}

.card .frontCard {
}

.card .backCard {
  transform: rotateY(180deg);
}

.card.flipped {
  transform: rotateY(180deg);
}

#toggleDraggableDiv {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: end;
  padding-right: 10px;
}

.tooltipText {
  position: absolute;
  transform: translate3d(0px, -45px, 0px);
  white-space: nowrap;
  padding: 5px 10px;
  background-color: #cc3366;
  color: #fff;
  border-radius: 10px;
  border: 1px solid white;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.5s ease;
}
.tooltipTextTop {
  position: absolute;
  top: -100%;
  background-color: #cc3366;
  padding: 5px;
  color: #fff;
  border-radius: 10px;
  border: 1px dashed white;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.5s ease;
}
/* .tooltipText::before {
  content: "";
  position: absolute;
  left: 100%;
  top: 0%;
  border: 15px solid;
  border-color: #0000 #0000 #0000 #cc3366;
} */

.my-tooltip:hover .tooltipText {
  visibility: visible;
  opacity: 1;
}
.tooltip-wrapper:hover .tooltipTextTop {
  visibility: visible;
  opacity: 1;
}
/* .tooltipTextTop::before {
  content: "";
  position: absolute;
  left: 30%;
  top: 100%;
  border: 15px solid;
  border-color: #cc3366 #0000 #0000 #0000;
} */

@media screen and (max-width: 576px) {
  #welcome-div-top {
    height: 25vh;
  }
  .icons img {
    width: 60px;
  }
  .buttonarea {
    /*display: inline-block;*/
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #cc3366;
    width: 100%;
    height: -moz-fit-content;
    height: fit-content;
    width: 100vw;
  }
  .flip-container {
    perspective: 1000px;
    transform-style: preserve-3d;
    display: inline-block;
    vertical-align: middle;
    padding: 10px 0px;
    margin: 20px;
  }
  .flip-container,
  .front,
  .back {
    width: 90vw;
    height: 90vw;
  }
  #outlinedPinkBtn {
    width: 60px;
    /* display: none; */
  }
  #pinkBtn {
    width: 80px;
  }
}
@media screen and (min-width: 576px) and (max-width: 1000px) {
  .icons img {
    margin-bottom: 30px;
    margin-right: 10px;
    margin-left: 5px;
    margin-top: 10px;
    text-decoration: none;
    width: 60px;
  }
  .buttonarea {
    /*display: inline-block;*/
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #cc3366;
    width: 100%;
    height: -moz-fit-content;
    height: fit-content;
    width: 100vw;
    padding: 0px;
  }
  .flip-container,
  .front,
  .back {
    width: 70vw;
    height: 40vw;
  }
}
@media screen and (max-height: 600px) {
  #welcome-div-top {
    height: 20vh;
  }
  .flip-container,
  .front,
  .back {
    width: 30vw;
    height: 30vw;
  }
  .buttonarea {
    flex-direction: row;
    width: 100vw;
  }
  .buttonarea h2 {
    font-size: large;
  }
  .buttonarea p {
    font-size: medium;
    padding: 0px;
    margin: 10px;
  }
}

@media screen and (max-height: 380px) {
  #welcome-div-top {
    height: 30vh;
  }
  .flip-container,
  .front,
  .back {
    width: 30vw;
    height: 30vw;
  }
  .front img {
    scale: 0.7;
  }
  .buttonarea {
    flex-direction: row;
  }
  .buttonarea h2 {
    font-size: medium;
  }
  .buttonarea p {
    font-size: small;
    padding: 0px;
    margin: 10px;
  }
  #form1 input {
    height: -moz-fit-content;
    height: fit-content;
    font-size: 14px;
  }
}
