[data-v-f0847479]:root,
html[data-v-f0847479] {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
    "Liberation Mono", "Courier New", monospace;
}
html[data-v-f0847479] {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
}
body[data-v-f0847479] {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
  overflow-x: hidden !important;
  overflow-y: hidden !important;
}
*[data-v-f0847479],[data-v-f0847479]::after,[data-v-f0847479]::before {
  box-sizing: border-box;
}
#root[data-v-f0847479] {
  height: 100%;
}
a[data-v-f0847479] {
  color: #FFFFFF;
  text-decoration: none;
  background-color: transparent;
}
img[data-v-f0847479] {
  vertical-align: middle;
  border-style: none;
}
.carousel[data-v-f0847479] {
  position: relative;
}
.carousel-inner[data-v-f0847479] {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-item-next[data-v-f0847479],
.carousel-item-prev[data-v-f0847479],
.carousel-item.active[data-v-f0847479] {
  display: block;
}
.carousel-item[data-v-f0847479] {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
.carousel-control-next[data-v-f0847479],
.carousel-control-prev[data-v-f0847479] {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  background-color: transparent !important;
  transition: opacity 0.15s ease;
  display: none;
}
.carousel-inner[data-v-f0847479]::after {
  display: block;
  clear: both;
  content: "";
}
/* .carousel-control-prev {
  left: 0;
}
[role="button"] {
  cursor: pointer;
} */
.sr-only[data-v-f0847479] {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}
.grid-container[data-v-f0847479] {
  height: 100vh;
  background-color: #fff;
  display: grid;
  position: relative;
  grid-template-rows: 50% 50%;
  /* grid-template-rows: 100%; */
}
.overlay[data-v-f0847479] {
  position: absolute; /* We're using absolute positioning within the grid container */
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  background-color: #171616;  /*semi-transparent black background */
  z-index: 200;
  opacity: 90%;
}
.top-container-div[data-v-f0847479] {
  min-width: 0;
  text-align: center;
  width: auto;
  position: relative;
  /* background-color: red; */
  /* padding: 0px;  */
}
#vimeoPlayerContainer[data-v-f0847479] {
  margin: auto;
  /* margin-top: 1vh; */
  display: flex;
  align-items: center;
  justify-content: center;
  /* height: 48vh; */
  pointer-events: none;
  /* width: auto;
  height: 48vh; */
}

/* .carousel-control-prev span,
.carousel-control-next span {
  background-image: url("../img/btn_arrow_left.png") !important;
  color: black;
} */
.video-div[data-v-f0847479] {
  display: flex;
  align-items: center;
}
#screen-img[data-v-f0847479] {
  position: absolute;
  opacity: .7;
  width : 582px;
  object-fit: fill;
}
#start-img[data-v-f0847479] {
  position: absolute;
  /* box-shadow: 0px 0px 20px #000; */
  border-radius: 20px;
  padding: 10px;
  /* background-color: #ffffff88; */
  animation: pulseAnim-data-v-f0847479;
  animation-duration: 1.5s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 3;
  z-index: 2;
}
@keyframes pulseAnim-data-v-f0847479 {
0% {
    scale: 1;
    transform: translateY(0px);
}
25% {
    scale: 1.05;
    transform: translateY(20px);
}
50% {
    scale: 1.1;
    transform: translateY(0px);
}
750% {
    scale: 1.05;
    transform: translateY(-20px);
}
100% {
    scale: 1;
    transform: translateY(0px);
}
}
.track video[data-v-f0847479] {
  /* object-fit: contain; */
  position: relative;
  width: auto;
  height: 100%;
}
.track video disabled[data-v-f0847479] {
  background-image: url("../img/person-placeholder.png") !important;
}
.react-player[data-v-f0847479] {
  /* height: 98%; */
  height: 100%;
  padding-top: 56.25%;
  position: relative;
}
.react-player > div[data-v-f0847479] {
  position: absolute;
}
.nav-btn-div[data-v-f0847479] {
  display: flex;
  flex-direction: row;
  background: rgb(33, 33, 33);
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 50px 100px #000000ff;
  /* animation */
  opacity: 1;
  transition: opacity 1.5s;
}
.nav-btn-div[data-v-f0847479]:hover {
  opacity: 1;
  transition: opacity 1.5s;
}
#navigation-btns[data-v-f0847479] {
  margin-right: 60px;
}
.nav-btns[data-v-f0847479] {
  display: flex;
  flex-direction: row;
  padding: 0px;
  margin-left: 60px;
}
#room-name-tag[data-v-f0847479] {
  margin-left: 60px;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
}
.RoomNameTag[data-v-f0847479] {
  font-size: 15px;
  margin-right: 10px;
}
.room-name-hide[data-v-f0847479] {
  color: #cc3366;
  font-weight: 700;
  cursor: default;
}
.control-button-div[data-v-f0847479] {
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: rgb(33, 33, 33);
  height: 60px;
  width: 100%;
  height: 60px;
  padding-left: 60px;
  padding-right: 60px;
  bottom: -1px;
  margin-bottom: 0px;
  box-shadow: 0px -50px 100px #000000ff;
  z-index: 1010;
}
.control-button-div[data-v-f0847479]:hover {
  opacity: 1;
  transition: opacity 1.5s;
}

/* Style The Dropdown Button */
.dropbtn[data-v-f0847479] {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  background-color: rgb(103, 103, 103);
  color: white;
  margin: 0;
  padding: 10px;
  font-size: 18px;
  border: none;
  cursor: pointer;
  border-radius: 10px;
  max-width: 165px;
  height: 45px;
}

/* The container <div> - needed to position the dropdown content */
.custom-dropdown[data-v-f0847479] {
  position: relative;
  display: inline-block;
  margin: 5px;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content[data-v-f0847479] {
  display: none;
  position: absolute;
  font-weight: 600;
  font-size: 16px;
  background-color: #1716167a;
  min-width: 150px;
  padding: 5px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  transform: translate3d(-6px, -100%, 0px);
  left: 0;
  color: #cc3366;
  border-radius: 10px;
}

/* Links inside the dropdown */
.dropdown-content a[data-v-f0847479] {
  color: white;
  text-decoration: none;
  display: block;
  border-radius: 10px;
  height: 60px;
  width: 100%;
  padding: 0px;
  margin-bottom: 10px;
}

/* Change color of dropdown links on hover */
.dropdown-content a[data-v-f0847479]:hover {
  background-color: rgb(103, 103, 103);
  color: white;
}

/* Show the dropdown menu on hover */
.custom-dropdown:hover .dropdown-content[data-v-f0847479] {
  display: block;
}

/* Change the background color of the dropdown button when the dropdown content is shown */
.custom-dropdown:hover .dropbtn[data-v-f0847479] {
  background-color: #cc3366;
}
.energy-level-logo[data-v-f0847479] {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: -moz-fit-content;
  width: fit-content;
  height: -moz-fit-content;
  height: fit-content;
  /* margin-right: 2px; */
}
.duration-logo[data-v-f0847479] {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: -moz-fit-content;
  width: fit-content;
  height: -moz-fit-content;
  height: fit-content;
}
.fullscreen-btn[data-v-f0847479] {
  background-color: rgb(103, 103, 103) !important;
}
.fullscreen-btn[data-v-f0847479]:hover {
  background-color: #cc3366 !important;
}
/* .control-button-div:hover .fullscreen-btn {
  display: flex !important;
} */
.my-tooltip[data-v-f0847479] {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  height: 45px;
  width: 45px;
  z-index: 2000;
  cursor: pointer;
  border-radius: 10px;
  margin: 5px;
}
.my-tooltip svg[data-v-f0847479] {
  fill: white;
}
#filter-div[data-v-f0847479] {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}
.filterBtn[data-v-f0847479] {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: rgb(103, 103, 103);
  color: #fff;
  height: 100%;
  width: 100%;
  border-radius: 10px;
}
.filterBtn[data-v-f0847479]:hover {
  background-color: #cc336667;
}
#time-div[data-v-f0847479] {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}
.TFilterBtn[data-v-f0847479] {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: rgb(103, 103, 103);
  color: #fff;
  height: 100%;
  width: 100%;
  border-radius: 10px;
}
.TFilterBtn[data-v-f0847479]:hover {
  background-color: #cc336667;
}
.filterActive[data-v-f0847479] {
  background-color: #cc3366;
}
.bottom-container-div[data-v-f0847479] {
  background-color: #00000000;
  align-items: center;
  height: 100%;
  padding: 0;
  border-radius: 20px;
  opacity: 1;
  /* box-shadow: 0px 0px 20px #00000088; */
}
.DraggableIcon[data-v-f0847479] {
  opacity: 1;
}
#video-divs[data-v-f0847479] {
  width: 100%;
  display: flex;
  align-items: center;
}

/* .nav-btn-div {
  position: absolute;
  right: 2vw;
  z-index: 100;
}

.link {
  margin: 1px;
}

.link img {
  height: 28px;
} */
#start-video-div[data-v-f0847479] {
  position: absolute;
  z-index: 100;
  display: flex;
  justify-content: center;
  width: 100%;
}
#start-video-div img[data-v-f0847479] {
  cursor: pointer;
}
#btnPlayImg[data-v-f0847479] {
  height: 45px;
  display: block;
}
#btnPlaySmall[data-v-f0847479] {
  height: 45px;
  background-color: #cc3366;
  font-family: inherit;
  font-weight: 600;
  font-size: larger;
  text-decoration: none;
  border-radius: 10px;
  padding: 10px;
  display: none;
}
#btnPlayDance[data-v-f0847479] {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #cc3366;
  border: 1px solid #cc3366;
  font-family: inherit;
  font-weight: 600;
  font-size: larger;
  text-decoration: none;
  border-radius: 10px;
  padding: 10px;
  width: 165px;
  cursor: pointer;
}
#btnPlayDance[data-v-f0847479]:hover {
  border: 2px solid #fff;
}
#buddy-video-div[data-v-f0847479] {
  max-height: 100%;
  height: -moz-fit-content;
  height: fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
#camera-video-container[data-v-f0847479] {
  display: flex;
  flex-direction: column;
}
.local-video-div-0[data-v-f0847479] {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: auto;
}
.local-video-div-1[data-v-f0847479] {
  display: grid;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  grid-template-columns: 50% 50%;
}
.local-video-div-2[data-v-f0847479] {
  display: grid;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  grid-template-columns: 30% 70%;
}
.local-video[data-v-f0847479] {
  max-height: 50vh;
  object-fit: cover;
  margin: 0;
  border-radius: 10px;
  object-fit: cover;
}
.remote-video[data-v-f0847479] {
  object-fit: cover;
}
.videoButton[data-v-f0847479] {
  border: solid 1px white;
  background-color: black;
}
.local-participant[data-v-f0847479] {
  width: 100% !important;
  height: auto !important;
  max-height: 45vh;
}
.local-participant-div[data-v-f0847479] {
  width: 100%;
  max-height: 100%;
}
.remote-participants-div[data-v-f0847479] {
  height: 100%;
  display: flex;
  
  align-content: center;
  justify-content: center;
}
.remote-participant-div[data-v-f0847479] {
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 1;
  max-width:50vw;
  min-width: 12vw;
  /* width: 100%; */
  align-items: center;
  justify-content: center;
}
.remote-participant[data-v-f0847479] {
  width: 100% !important;
  height: auto !important;
  max-height: 45vh;
}
.testBtnClass[data-v-f0847479] {
  position: absolute;
  left: 10px;
  z-index: 100;
}
#btnCamera[data-v-f0847479] {
  margin-top: 0px;
  border: transparent;
}
#btnPause[data-v-f0847479] {
  top: 40px;
}
#trackPlaceholderImg[data-v-f0847479] {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  z-index: 10;
}
.avControl[data-v-f0847479] {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 20;
  /* color: white; */
  /* opacity: .6; */
  cursor: pointer;
  font-size: 1rem;
}
.avControl>img[data-v-f0847479], #btnCamera[data-v-f0847479] {
  height: 25px;
  opacity: 50%;
}
.avControl>img[data-v-f0847479]:hover, #btnCamera[data-v-f0847479]:hover {
  opacity: 1;
}
.fa-microphone-slash[data-v-f0847479], .fa-video-slash[data-v-f0847479] {
  color: red;
}
.avaudio[data-v-f0847479] {
  margin-top: 30px;
}
.avvideo[data-v-f0847479] {
  margin-top: 60px;
}
.carousel-control-prev[data-v-f0847479] {
  left: 0;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  font-size: 14px;
  line-height: 1.42857143;
  font-family: "Nunito", sans-serif;
  box-sizing: border-box;
  outline: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease;
  left: 0;
  text-decoration: none;
  background-color: #fff;
  padding: 0;
  margin: 0;
  display: none;
}
.carousel-control-next[data-v-f0847479] {
  right: 0;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  font-size: 14px;
  line-height: 1.42857143;
  font-family: "Nunito", sans-serif;
  box-sizing: border-box;
  outline: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease;
  left: 0;
  text-decoration: none;
  background-color: #fff;
  padding: 0;
  margin: 0;
  display: none;
}
html[data-v-f0847479],
body[data-v-f0847479] {
  background-color: #fff !important;
  overflow-x: hidden !important;
  overflow-y: hidden !important;
}
.login-container[data-v-f0847479] {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.form-container[data-v-f0847479] {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
}
#turn-phone-popup[data-v-f0847479] {
  position: absolute;
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  z-index: 10000;
  background-color: #cc3366;
  color: #fff;
  font-size: 20px;
  text-align: center;
  padding: 20px;
  border-radius: 15px;
  box-shadow: #171616 0px 0px 30px 5px;
}
#rotatePhoneImage[data-v-f0847479] {
  animation: rotatePhoneAnim-data-v-f0847479 1.8s 1s infinite ease-in-out forwards;
}
@keyframes rotatePhoneAnim-data-v-f0847479 {
0% {
    transform: rotateZ(-45deg);
}
100% {
    transform: rotateZ(45deg);
}
}
@media screen and (max-width: 500px) {
#turn-phone-popup[data-v-f0847479] {
    display: flex;
}
}
@media (min-width: 685px) and (max-width: 1000px) {
.control-button-div[data-v-f0847479] {
    padding-left: 5px;
    padding-right: 5px;
}
}
@media (max-width: 685px) {
.dropdown-content[data-v-f0847479] {
    display: none;
    position: absolute;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    color: #cc3366;
    border-radius: 5px;
    transform: translate3d(-35%, -100%, 0px);
}
.dropdown-content2[data-v-f0847479] {
    /* transform: translate3d(-68%, -100%, 0px); */
}
#room-name-tag[data-v-f0847479] {
    margin-left: 10px;
}
.RoomNameTag[data-v-f0847479] {
    font-size: 10px;
}
.room-name-hide[data-v-f0847479] {
    /* display: none; */
}
#navigation-btns[data-v-f0847479] {
    margin-right: 10px;
}
.control-button-div[data-v-f0847479] {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 5px;
    padding-right: 5px;
}
.DraggableIcon[data-v-f0847479]{
    opacity: 0;
}
.dropbtn[data-v-f0847479] {
    background-color: rgb(103, 103, 103);
    color: white;
    padding: 10px;
    font-size: 16px;
    border: none;
    cursor: pointer;
    border-radius: 10px;
    width: 50px;
}
.energy-level-text[data-v-f0847479]{
    display: none;
}
.duration-text[data-v-f0847479] {
    display: none;
}
#btnPlayImg[data-v-f0847479] {
    display: none;
}
#btnPlayDance[data-v-f0847479] {
    display: none;
}
#btnPlaySmall[data-v-f0847479] {
    display: flex;
    align-items: center;
    justify-content: center;
}
#screen-img[data-v-f0847479] {
    display: none;
}
#start-img[data-v-f0847479] {
    z-index: 1000;
}
.local-video[data-v-f0847479] {
    width: 25vw;
}
.nav-btns[data-v-f0847479] {
    margin-left: 0px;
}
}
@media (max-height: 500px) {
.local-video[data-v-f0847479] {
    width: 45vh;
}
.remote-video[data-v-f0847479] {
    width: 45vh;
}
#room-name-tag[data-v-f0847479] {
    margin-left: 10px;
}
#navigation-btns[data-v-f0847479] {
    margin-right: 10px;
}
}
@media (max-width: 410px) {
.custom-dropdown[data-v-f0847479] {
    display: none;
}
}
