.p-social {
  font-family: Roboto, sans-serif;
  font-size: 1.3rem;
}

.buttonarea {
  margin-bottom: 20px;
}

.footer-btn {
  width: 20rem;
  max-width: 90%;
  line-height: 3rem;
  margin: 0.5rem;
  border-radius: 12px;
  font-size: 1.5rem;
  text-decoration: none;
  border-style: solid;
  font-weight: 700;
}

.footer-btn:hover {
  color: black;
}

.btn-login {
  background-color: #fff;
  color: #cc3366;
  border-color: #fff;
}

.btn-signup {
  background-color: #cc3366;
  color: #fff;
  border-color: #fff;
}

.footer-legal {
  font-family: "Roboto", sans-serif;
}
