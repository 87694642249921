.signup-container {
  margin-top: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* font-family: Nunito, sans-serif; */
}

.error-banner {
  display: flex;
  /* align-content: flex-start; */
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  margin: 0.5rem auto;
  width: 90%;
  background-color: yellow;
  border-radius: 4px;
  color: red;
}
.signup-container h1 {
  margin: 0;
  font-size: 2.3rem;
  font-weight: 500;
  /* text-align: center; */
}

.headline {
  text-align: center;
  font-size: 1.3rem;
  font-weight: 300;
  font-style: italic;
  margin: 0 2vw;
}

.welcome {
  font-family: Roboto, sans-serif;
  font-size: 1.5rem;
  font-weight: 300;
  margin: 1rem 2vw;
}

.signup-form {
  font-family: Roboto, sans-serif;
  display: flex;
  flex-direction: column;
  align-self: center;
  width: 30rem;
  max-width: 95%;
}

.signup-form-group {
  align-self: center;
  width: 80%;
  /* width: 25rem;
  max-width: 90%; */
}

.signup-form-group label {
  display: block;
  margin: 0.5rem 1rem;
  font-weight: bold;
}

.signup-form-group input {
  width: 100%;
  padding: 1rem;
  border: 1px solid #ccc;
  border-style: inset;
  border-radius: 4px;
  background-color: #f8f8f8;
}

.signup-form-checkbox {
  /* width: 35rem; */
}

.signup-form-checkbox input {
  /* width: 20px !important; */
}

.input-error {
  border: 0.2rem solid yellow !important;
}

.error-message {
  margin: 0.2rem 0;
  border-radius: 4px;
  display: flex;
  color: red;
  font-size: 1rem;
  background-color: yellow;
  /* height: 2.5rem; */
}

.error-img {
  margin: 0;
}

.error-message span {
  display: inline-flex;
  align-items: center;
}

.password-message {
  display: none;
}

#password:focus + #message1 {
  display: block;
}

.signup-btn {
  padding: 0.5rem 1.5rem;
  font-size: 1.5rem;
  font-weight: bold;
  background-color: #cc3366;
  border-radius: 12px;
  margin: 0.5rem;
  color: #fff;
  border: 2px solid #fff;
}

.signup-footer {
  font-family: Roboto, sans-serif;
  font-weight: 300;
  text-align: center;
}

#signup-success-page {
  /* min-height: 100vh; */
  height: 100vh;
  background-color: #fff;
}

.signup-success-container {
  height: 94vh;
  display: flex;
  flex-direction: row;
  /* flex-wrap: wrap; */
  border-top: #cc3366 solid 0.5rem;
  justify-content: center;
}

.signup-success-column {
  /* height: 93vh; */
}

#signup-success-left-side {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #cc3366;
}

#signup-success-right-side {
  display: flex;
  flex-direction: column;
  /* grid-template-rows: 80% 20%; */
  align-items: center;
  justify-content: space-between;
  text-align: center;
  color: black;
  padding: 0 2rem;
  background-color: #fff;
}

#success-right-upper {
  height: 79vh;
  overflow: scroll;
}

#success-right-upper p {
  font-size: 1.5rem;
  font-weight: 300;
}

#success-right-lower {
  height: 15vh;
}

@media (max-width: 850px) {
  .signup-success-container {
    flex-direction: column;
    height: auto;
  }
  #register-dance-img {
    width: 100vw;
    height: auto;
  }

  #success-right-upper {
    height: auto;
    overflow: visible;
  }
}

#register-dance-img {
  /* max-width: 40vw; */
  /* min-width: unset; */
  max-height: 76vh;
  /* height: 100%; */
  object-fit: cover;
}

/* #signup-success-img {
  background-image: url("../assets/img/dance-quote-1.png");
  background-size: contain;
  background-repeat: no-repeat;
} */

#signup-success-social-container {
  height: 18vh;
  display: flex;
  flex-direction: column;
  color: #fff;
  text-align: center;
  align-self: center;
}

#social-container-header {
  font-size: 2rem;
}

.signup-success-icons {
  display: flex;
  justify-content: center;
}

.signup-success-icons img {
  margin-bottom: 0px;
  margin-right: 8px;
  margin-left: 8px;
  margin-top: 8px;
  text-decoration: none;
  width: 60px;
}

#social-container-website-link {
  font-size: 1.5rem;
}
