/* Tooltip container */
.tooltip[data-v-149ad4fd] {
    opacity: 100;
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted black;
}
  
  /* Tooltip text */
.tooltip .tooltiptext[data-v-149ad4fd] {
    visibility: hidden;
    width: 100%;
    background-color: #cc3366;
    border: 2px solid #e7f036;
    color: white;
    font-family: 'Nunito', sans-serif;
    font-size: 14px;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    box-shadow: 5px 5px 25px #802040;
    
    /* Position the tooltip */
    position: absolute;
    z-index: 1;
    transform: translate3d(50%, -80%, 0);
}
  
  /* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext[data-v-149ad4fd] {
    visibility: visible;
}

  /* Border around error messages */
.borderError[data-v-149ad4fd] {
    width: 100%;
    border: 2px solid #e7f036;
    color: white;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
}