.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 3rem;
  /* font-family: Nunito, sans-serif; */
}

#error-banner {
  display: flex;
  /* align-content: flex-start; */
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  margin: 0.5rem auto;
  width: 90%;
  background-color: yellow;
  border-radius: 4px;
  color: red;
}

.login-container h1 {
  text-align: center;
}

.headline {
  text-align: center;
  font-size: 2rem;
  font-weight: 300;
  font-style: italic;
  margin: 0 2vw;
}

.login-form {
  font-family: Roboto, sans-serif;
  width: 30rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.form-group {
  width: 25rem;
  max-width: 90%;
}

.form-group label {
  display: block;
  margin: 0.5rem 1rem;
}

.form-group input {
  width: 100%;
  padding: 1rem;
  border: 1px solid #ccc;
  border-style: inset;
  border-radius: 4px;
  background-color: #f8f8f8;
}

.form-checkbox {
  width: 25rem;
  max-width: 90%;
}

.form-checkbox input {
  width: 20px !important;
}

.input-error {
  border: 0.2rem solid yellow !important;
}

.error-message {
  margin: 0.2rem 0;
  border-radius: 4px;
  display: flex;
  color: red;
  font-size: 1rem;
  background-color: yellow;
  /* height: 2.5rem; */
}

.error-img {
  margin: 0;
}

.error-message span {
  display: inline-flex;
  align-items: center;
}

.password-message {
  display: none;
}

#password:focus + #message1 {
  display: block;
}

.login-btn {
  padding: 0.5rem 1.5rem;
  font-size: 1.5rem;
  background-color: #cc3366;
  border-radius: 12px;
  margin: 0.5rem;
  color: #fff;
  border: 2px solid #fff;
}

.login-footer {
  font-family: Roboto, sans-serif;
  font-weight: 300;
  text-align: center;
}
