
.banner-parent-container[data-v-c5792c10] {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  box-sizing: border-box;
  background-color: white;
}

/* Layout Styles */
.banner-layout[data-v-c5792c10] {
  display: flex;
  width: 100%;
  max-width: 1200px;
  align-items: center;
}
.banner-layout__item[data-v-c5792c10] {
  flex: 1 1;
  padding: 1rem;
}
.banner-layout__item--left[data-v-c5792c10] {
  flex: 0 0 20%;
}
.banner-layout__item--middle[data-v-c5792c10] {
  flex: 10 0 60%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-around;
  text-wrap: nowrap;
  margin-top: auto;
  margin-bottom: 3rem;
}
.banner-layout__item--right[data-v-c5792c10] {
  flex: 0 0 20%;
}

/* Content Styles */
.banner-content[data-v-c5792c10] {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  align-items: flex-start; /* `baseline` is another possible value*/
}
.banner-content__header[data-v-c5792c10] {
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
}
.banner-content__header--pink[data-v-c5792c10] {
  color: rgb(210, 61, 86);
  font-size: 3.0rem;
  font-weight: bold;
  text-align: justify;
  text-wrap: nowrap;
}
.banner-content__header--black[data-v-c5792c10] {
  color: black;
  font-size: 2.2rem;
  font-weight: bold;
}
.banner-content__text[data-v-c5792c10] {
  font-size: 3rem;
  text-align: center;
  max-width: 80%;
  line-height: 0.35;
  color: black;
  margin: 0 auto;
}
.banner-content__text--large[data-v-c5792c10] {
  font-size: 5rem;
}
.banner-content__image[data-v-c5792c10] {
  width: 100%;
  /* max-width: 200px; */
  height: auto;
}
