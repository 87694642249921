#home-container {
  height: 100vh;
  text-align: center;
}

.mainlogoarea {
  background-image: url("../assets/img/background.jpg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: 43vh;
  background-color: #fff;
}

/* @media (max-width: 1200px) {
  .mainlogoarea {
  }
} */

/* @media (max-width: 1000px) {
  .mainlogoarea {
    height: 300px;
  }
} */

@media (max-width: 620px) {
  .mainlogoarea {
    height: 25vh;
  }
}
