
body[data-v-c30d2115] {
  background-color: white;
}

/**
* styling for admin edit form input for documentation questions
*/
#admin-documentation-questions--form-group[data-v-c30d2115],#admin-documentation-questions--form-group-question-text[data-v-c30d2115], #admin-documentation-questions--form-group-answer-text[data-v-c30d2115]    {
*, select {width:90%;}
  width: 80%;
}
.help-questions-collapsing[data-v-c30d2115] {
  position: relative;
  /* height: 0; */
  /* overflow: hidden; */
  transition-timing-function: ease;
  transition-duration: 2s;
  transition-property: height, visibility;
  transition: height ease 4.9s;
  transition: visibility ease 4.9s;
  overflow:hidden;
}
#welcomeXX[data-v-c30d2115] {
  /* display: flex;
  flex-direction: column; */
  height: 100vh;
  width: 100%;
  text-align: center;
  /* opacity: 50%;
  background-color: rgba(0, 0, 0, 0.9);    */
}
.welcome-overlayXX[data-v-c30d2115] {
  position: absolute;  /* Fixed to screen */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #171616; 
    /* Semi-transparent */
  z-index: 9;  /* Z-index set to 9 */
  opacity: 90%;
}
#XXXXwelcome-div-top[data-v-c30d2115] {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  /* height: 200px; */
  height: 25vh;
  width: 100%;
  text-align: center;
  color: #cc3366;
  background-image: none;
}
.logo-img-wrapper[data-v-c30d2115] {
  height: max-content;
  width: auto;
}
#logo-img[data-v-c30d2115] {
  width: 100%;
  height: auto;
}
@media (max-height: 600px) {
#logo-img[data-v-c30d2115] {
    width: 45%;
    height: auto;
}
}
#welcome-div-bottom[data-v-c30d2115] {
  display: flex;
  margin: 0px;
  padding: 0px;
  justify-content: center;
  align-items: center;
  height: 45vh;
  width: 100%;
  background-color: #cc3366;
  color: #f5f5f5;
  height: max-content;
}
#welcome-div-footer[data-v-c30d2115] {
  height: 30vh;
  width: 100%;
  padding: 10px;
}
.icons img[data-v-c30d2115] {
  margin-bottom: 30px;
  margin-right: 10px;
  margin-left: 5px;
  margin-top: 10px;
  text-decoration: none;
  width: 70px;
}

/* Navigation Buttons */
.nav-btn-div[data-v-c30d2115] {
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: end;
  padding-right: 25px;
  width: 100%;
  background: rgb(33, 33, 33, 0);
  height: 60px;
  overflow-y: hidden;
  overflow-x: hidden;
  z-index: 1000;
}
.link img[data-v-c30d2115] {
  height: 28px;
}
#homeLogin[data-v-c30d2115] {
  position: fixed;
  top: 0px;
  right: 20px;
  /* display: flex;
    flex-direction: row; */
}
#homeBtn[data-v-c30d2115] {
  height: 28px;
}
#profileBtn[data-v-c30d2115] {
  height: 28px;
}
#homeLogin #submit[data-v-c30d2115] {
  padding: 0;
  border: none;
  background: none;
}
#LogoutBtn[data-v-c30d2115] {
  height: 28px;
}

/* FLIP CARDS MENU */
.buttonarea[data-v-c30d2115] {
  /*display: inline-block;*/
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #cc3366;
  height: max-content;
  width: 100vw;
  padding: 0;
}
.buttonarea h2[data-v-c30d2115] {
  color: #cc3366;
  font-size: 26px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: 800;
  margin: 0px !important;
}
.buttonarea p[data-v-c30d2115] {
  font-size: large;
  padding: 0px;
  margin: 15px 30px;
}
.attendclass[data-v-c30d2115] {
  background-color: #fff;
  width: 260px;
  height: 260px;
  border-radius: 20px;
  display: inline-block;
  vertical-align: middle;
}
.gap[data-v-c30d2115] {
  display: inline-block;
  width: 10px;
}
#form1[data-v-c30d2115] {
  color: #cc3366;
  margin: 0px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
#form1 input[data-v-c30d2115] {
  height: 50px;
  width: 80%;
  font-size: 16px;
  font-weight: 500;
  width: auto;
}
.room[data-v-c30d2115] {
  height: 25px;
  border-radius: 10px;
  border-style: solid;
  text-align: center;
  margin-bottom: 9px;
}
.btnGo[data-v-c30d2115] {
  height: auto;
  width: 50px;
  margin: 0px;
  padding: 5px;
  border-radius: 10px;
  color: #fff;
  background-color: #cc3366;
  border-style: solid;
  border-color: #cc3366;
}
.row[data-v-c30d2115] {
  margin: 0px !important;
}

/* FLIP */
.flip-container[data-v-c30d2115] {
  perspective: 1000px;
  transform-style: preserve-3d;
  display: inline-block;
  vertical-align: middle;
  margin: 20px 10px;
}
.flip-container:hover .back[data-v-c30d2115] {
  transform: rotateY(0deg);
}
.flip-container:hover .front[data-v-c30d2115] {
  transform: rotateY(180deg);
}
.flip-container[data-v-c30d2115],
.front[data-v-c30d2115],
.back[data-v-c30d2115] {
  width: 350px;
  height: 350px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.flip-container button[data-v-c30d2115] {
  font-size: larger;
  font-weight: bolder;
  width: 130px;
}
.flipper[data-v-c30d2115] {
  transition: 1s;
  transform-style: preserve-3d;
  position: relative;
}
.front[data-v-c30d2115],
.back[data-v-c30d2115] {
  backface-visibility: hidden;
  transition: 1s;
  transform-style: preserve-3d;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #fff;
  border-radius: 20px;
}
.front[data-v-c30d2115] {
  z-index: 0;
  transform: rotateY(0deg);
  position: relative;
  align-items: center;
}
.front img[data-v-c30d2115] {
  padding-bottom: 20px;
  /* align-items: flex-end; */
}
#fimg3[data-v-c30d2115] {
  padding-top: 10px;
}
.back[data-v-c30d2115] {
  transform: rotateY(-180deg);
  margin-top: 0px;
  box-shadow: 15px 10px 10px #6d1b37;
}
.back p[data-v-c30d2115] {
  font-size: 18px;
  color: #000;
  text-align: center;
}
img.map[data-v-c30d2115],
map area[data-v-c30d2115] {
  outline: none;
}
.container[data-v-c30d2115] {
  font-family: "Roboto", sans-serif;
}
.flipcontainer[data-v-c30d2115] {
  width: 200px;
  height: 180px;
  position: relative;
  border: 0px;
  -o-perspective: 800px;
  perspective: 800px;
  margin-top: 10px;
}

/* .card {
  width: 100%;
  height: 100%;
  position: absolute;
  -webkit-transition: -webkit-transform 1s;
  -moz-transition: -moz-transform 1s;
  -o-transition: -o-transform 1s;
  transition: transform 1s;
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -o-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transform-origin: 50% 50%;
} */
.cardXXXXX divxxxxx[data-v-c30d2115] {
  display: block;
  height: 100%;
  width: 100%;
  /* line-height: 260px; */
  /* text-align: center; */
  font-weight: bold;
  /* font-size: 140px; */
  /* position: absolute; */
  backface-visibility: hidden;
}

/* .card .frontCard {
} */
.card .backCard[data-v-c30d2115] {
  transform: rotateY(180deg);
}
.card.flipped[data-v-c30d2115] {
  transform: rotateY(180deg);
}
#toggleDraggableDiv[data-v-c30d2115] {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: end;
  padding-right: 10px;
}
.tooltipText[data-v-c30d2115] {
  position: absolute;
  left: -50%;
  top: -55%;
  white-space: nowrap;
  padding: 5px 10px;
  background-color: #cc3366;
  color: #fff;
  border-radius: 5px;
  border: 1px dashed white;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.5s ease;
}
.tooltipTextTop[data-v-c30d2115] {
  position: absolute;
  top: -100%;
  background-color: #cc3366;
  padding: 5px;
  color: #fff;
  border-radius: 5px;
  border: 1px dashed white;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.5s ease;
}
/* .tooltipText::before {
  content: "";
  position: absolute;
  left: 100%;
  top: 0%;
  border: 15px solid;
  border-color: #0000 #0000 #0000 #cc3366;
} */
.my-tooltip:hover .tooltipText[data-v-c30d2115] {
  visibility: visible;
  opacity: 1;
}
.tooltip-wrapper:hover .tooltipTextTop[data-v-c30d2115] {
  visibility: visible;
  opacity: 1;
}
/* .tooltipTextTop::before {
  content: "";
  position: absolute;
  left: 30%;
  top: 100%;
  border: 15px solid;
  border-color: #cc3366 #0000 #0000 #0000;
} */
@media screen and (max-width: 576px) {
#welcome-div-top[data-v-c30d2115] {
    height: 25vh;
}
.icons img[data-v-c30d2115] {
    width: 60px;
}
.buttonarea[data-v-c30d2115] {
    /*display: inline-block;*/
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #cc3366;
    width: 100%;
    height: -moz-fit-content;
    height: fit-content;
    width: 100vw;
}
.flip-container[data-v-c30d2115] {
    perspective: 1000px;
    transform-style: preserve-3d;
    display: inline-block;
    vertical-align: middle;
    padding: 10px 0px;
    margin: 20px;
}
.flip-container[data-v-c30d2115],
  .front[data-v-c30d2115],
  .back[data-v-c30d2115] {
    width: 90vw;
    height: 90vw;
}
}
@media screen and (min-width: 576px) and (max-width: 1000px) {
.icons img[data-v-c30d2115] {
    margin-bottom: 30px;
    margin-right: 10px;
    margin-left: 5px;
    margin-top: 10px;
    text-decoration: none;
    width: 60px;
}
.buttonarea[data-v-c30d2115] {
    /*display: inline-block;*/
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #cc3366;
    width: 100%;
    height: -moz-fit-content;
    height: fit-content;
    width: 100vw;
    padding: 0px;
}
.flip-container[data-v-c30d2115],
  .front[data-v-c30d2115],
  .back[data-v-c30d2115] {
    width: 70vw;
    height: 40vw;
}
}
@media screen and (max-height: 600px) {
#welcome-div-top[data-v-c30d2115] {
    height: 20vh;
}
.flip-container[data-v-c30d2115],
  .front[data-v-c30d2115],
  .back[data-v-c30d2115] {
    width: 30vw;
    height: 30vw;
}
.buttonarea[data-v-c30d2115] {
    flex-direction: row;
    width: 100vw;
}
.buttonarea h2[data-v-c30d2115] {
    font-size: large;
}
.buttonarea p[data-v-c30d2115] {
    font-size: medium;
    padding: 0px;
    margin: 10px;
}
}
@media screen and (max-height: 380px) {
#welcome-div-top[data-v-c30d2115] {
    height: 30vh;
}
.flip-container[data-v-c30d2115],
  .front[data-v-c30d2115],
  .back[data-v-c30d2115] {
    width: 30vw;
    height: 30vw;
}
.front img[data-v-c30d2115] {
    scale: 0.7;
}
.buttonarea[data-v-c30d2115] {
    flex-direction: row;
}
.buttonarea h2[data-v-c30d2115] {
    font-size: medium;
}
.buttonarea p[data-v-c30d2115] {
    font-size: small;
    padding: 0px;
    margin: 10px;
}
#form1 input[data-v-c30d2115] {
    height: -moz-fit-content;
    height: fit-content;
    font-size: 14px;
}
}