.confirm {
  height: 100vh;
}

.confirm-container {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  margin: auto;
  /* border: #fff solid 2px; */
  font-size: 2rem;
  width: 80vw;
}

.not-verified-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding: 2rem;
  /* border: #fff solid 2px; */
  font-size: 1.5rem;
}

.confirm-img-div {
  background-color: #fff;
  /* max-width: 80vw; */
  width: 100%;
  display: flex;
}

.confirm-img {
  margin: auto;

  /* max-width: 80vw; */
}

.success-div {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  margin: 1em;
}

.success-div > div {
  /* border-bottom: 2px solid #fff; */
  margin: 2vh auto;
}

.link {
  margin: 1em auto;
  /* color: green; */
}

.confirm-btn {
  padding: 0.5rem 1.5rem;
  font-size: 1.5rem;
  font-weight: bold;
  background-color: #cc3366;
  border-radius: 12px;
  margin: 0.5rem;
  color: #fff;
  border: 2px solid #fff;
}

.resend-div {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.resend-div p {
  text-align: center;
}
