.navbar {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: #fff;
  height: 6vh;
}

.navlink {
  display: flex;
  align-items: center;
  padding: 0 0.5rem;
  vertical-align: middle;
  font-family: Roboto, sans-serif;
  font-weight: 400;
  text-decoration: none;
  height: 100%;
}

.navlink img {
  height: 100%;
  object-fit: cover;
  margin: 0 1rem;
}

.navlink:hover {
  font-weight: bold;
}

.navlink.inactive {
  color: #666666;
  /* border-left: 0.2rem solid #e7e7e7; */
}

.navlink.active {
  color: #cc3366;
  background-color: #e7e7e7;
}
