.social-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.social-icons a {
  margin: 0.5rem;
}

.social-icons img {
  width: 4rem;
}
