.container[data-v-11486a65] {
  background-color: #fff;
  color: #585858;
  margin-top: 70px;
  padding: 10px;
  border: solid 10px #f2f2f2;
}
#homeLogin[data-v-11486a65] {
  position: fixed;
  top: 0px;
  right: 20px;
  display: flex;
  flex-direction: row;
  z-index: 1000;
}
#homeBtn[data-v-11486a65] {
  height: 28px;
}
#homeLogin #submit[data-v-11486a65] {
  padding: 0;
  border: none;
  background: none;
}
#LogoutBtn[data-v-11486a65] {
  height: 28px;
}
#profileBtn[data-v-11486a65] {
  height: 28px;
  margin: auto;
}
.profile-submit-btn[data-v-11486a65] {
  padding: 0.5rem 1.5rem;
  font-size: 1.5rem;
  font-weight: bold;
  background-color: #337ab7;
  border-radius: 12px;
  margin: 0.5rem;
  color: #fff;
  border: 2px solid #fff;
}
.status-message[data-v-11486a65] {
  color: #4cbe4c;
  text-transform: uppercase;
  margin-left: 10px;
  font-size: 16px;
  font-weight: 700;
}
.error-message[data-v-11486a65] {
  color: #ff0000;
  text-transform: uppercase;
  margin-left: 10px;
  font-size: 16px;
  font-weight: 700;
}
.widget-author[data-v-11486a65] {
  margin-bottom: 58px;
}
.author-card[data-v-11486a65] {
  position: relative;
  padding-bottom: 48px;
  background-color: #fff;
  box-shadow: 0 12px 20px 1px rgba(64, 64, 64, 0.09);
}
.author-card .author-card-cover[data-v-11486a65] {
  position: relative;
  width: 100%;
  height: 100px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.author-card .author-card-cover[data-v-11486a65]::after {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  opacity: 0.5;
}
.author-card .author-card-cover > .btn[data-v-11486a65] {
  position: absolute;
  top: 12px;
  right: 12px;
  padding: 0 10px;
}
.author-card .author-card-profile[data-v-11486a65] {
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 5;
}
.author-card .author-card-profile .author-card-avatar[data-v-11486a65] {
  width: 200px;
  margin-top: 20px;
  border-radius: 20%;
  box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.15);
  overflow: hidden;
}
.author-card .author-card-profile .author-card-avatar > img[data-v-11486a65] {
  display: block;
  width: 100%;
}
.author-card .author-card-profile .author-card-details[data-v-11486a65] {
  padding-top: 20px;
  text-align: center;
}
.author-card .author-card-profile .author-card-name[data-v-11486a65] {
  margin-bottom: 2px;
  font-size: 14px;
  font-weight: bold;
  color: #000;
}
.author-card .author-card-profile .author-card-position[data-v-11486a65] {
  display: block;
  color: #8c8c8c;
  font-size: 12px;
  font-weight: 600;
}
.author-card .author-card-info[data-v-11486a65] {
  margin-bottom: 0;
  padding: 0 25px;
  font-size: 13px;
}
.author-card .author-card-social-bar-wrap[data-v-11486a65] {
  position: absolute;
  bottom: -18px;
  left: 0;
  width: 100%;
}
.author-card .author-card-social-bar-wrap .author-card-social-bar[data-v-11486a65] {
  display: table;
  margin: auto;
  background-color: #fff;
  box-shadow: 0 12px 20px 1px rgba(64, 64, 64, 0.11);
}
input[type="file"][data-v-11486a65] {
  display: none;
}
.img-upload[data-v-11486a65]:before {
  content: "Change";
  font-size: 30px;
  position: absolute;
  top: 40%;
  border-radius: 25px;
  width: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #ffffff;
  opacity: 0;
  transition: 0.5s ease;
  background-color: #cc3366;
  cursor: pointer;
}
.img-upload[data-v-11486a65]:hover:before {
  opacity: 1;
}
.info-fields[data-v-11486a65] {
  padding: 10px;
}
.btn-style-1.btn-white[data-v-11486a65] {
  background-color: #fff;
}
.list-group-item i[data-v-11486a65] {
  display: inline-block;
  margin-top: -1px;
  margin-right: 8px;
  font-size: 1.2em;
  vertical-align: middle;
}
.mr-1[data-v-11486a65],
.mx-1[data-v-11486a65] {
  margin-right: 0.25rem !important;
}
.profile-form-group-radio[data-v-11486a65] {
  display: flex;
  justify-content: flex-start;
  gap: 2rem;
  width: 25rem;
}
.profile-radio-input[data-v-11486a65] {
  margin: 0;
}
.list-group-item.active[data-v-11486a65]:not(.disabled) {
  border-color: #e7e7e7;
  background: #fff;
  color: #ac32e4;
  cursor: default;
  pointer-events: none;
}
.list-group-flush:last-child .list-group-item[data-v-11486a65]:last-child {
  border-bottom: 0;
}
.list-group-flush .list-group-item[data-v-11486a65] {
  border-right: 0 !important;
  border-left: 0 !important;
}
.list-group-flush .list-group-item[data-v-11486a65] {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}
.list-group-item.active[data-v-11486a65] {
  z-index: 2;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.list-group-item[data-v-11486a65]:last-child {
  margin-bottom: 0;
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
a.list-group-item[data-v-11486a65],
.list-group-item-action[data-v-11486a65] {
  color: #404040;
  font-weight: 600;
}
.list-group-item[data-v-11486a65] {
  padding-top: 16px;
  padding-bottom: 16px;
  transition: all 0.3s;
  border: 1px solid #e7e7e7 !important;
  border-radius: 0 !important;
  color: #404040;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  text-decoration: none;
}
.list-group-item[data-v-11486a65] {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.list-group-item.active[data-v-11486a65]:not(.disabled)::before {
  background-color: #ac32e4;
}
.list-group-item[data-v-11486a65]::before {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 3px;
  height: 100%;
  background-color: transparent;
  content: "";
}
